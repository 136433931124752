import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevronLeft.svg';
import clsx from 'clsx';
import { athleteDifficultyLevelOptions } from 'common/constants';
import useAppNaviagte from 'common/hooks/useAppNaviagate';
import ImageInputFile from 'common/ui/form/dndFileInput/ImageInputFile';
import { SimpleSingleSelect } from 'common/ui/form/dropdown/SingleSelect';
import RadioButton from 'common/ui/form/RadioButton';
import Throbber from 'common/ui/Throbber';
import { convertToHTML } from 'draft-convert';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';

import useWorkoutDropDownOptions from '../DAL/useWorkputDropDownOptions';
import { WorkoutDetailsFormData, WorkOutDetailsFormSchema } from '../types';

interface WorkoutDetailsFormProps {
  defaultValues: WorkoutDetailsFormData;
  onSaveDraft: (formData: WorkoutDetailsFormData) => void;
  goNextSection: (formData: WorkoutDetailsFormData) => void;
  onSwapTo15: () => void;
}

function WorkoutDetailsForm(props: WorkoutDetailsFormProps) {
  const { defaultValues, onSaveDraft, goNextSection, onSwapTo15 } = props;
  const { categoryOptions, isOptionsLoading } = useWorkoutDropDownOptions({ autoLoad: true });
  const {
    control,
    formState: { errors },
    setError,
    clearErrors,
    handleSubmit,
    register,
    getValues,
    setValue,
    reset,
    watch,
  } = useForm<WorkoutDetailsFormData>({
    defaultValues,
    resolver: zodResolver(WorkOutDetailsFormSchema),
  });
  const typeValue = watch('type');
  const firstType = typeValue;
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (firstType !== typeValue && typeValue === 'quick') {
      onSwapTo15();
    }
  }, [typeValue, onSwapTo15, firstType]);
  // useEffect(() => {
  //   if (typeValue === 'quick') {
  //     onSwapTo15();
  //   }
  // }, [typeValue, onSwapTo15]);

  const { goBack } = useAppNaviagte();

  const onClickSaveAsDraft = async () => {
    clearErrors();
    const detailsPayload = { ...getValues() };
    if (detailsPayload.name.trim().length === 0) {
      setError('name', { type: 'custom', message: 'Atleast name is required to save draft.' });
    } else {
      onSaveDraft(detailsPayload);
    }
  };

  const [editorState, setEditorState] = useState(() => {
    const formData = getValues().description;
    if (formData) {
      let { contentBlocks, entityMap } = convertFromHTML(formData);
      const newContentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      return EditorState.createWithContent(newContentState);
    } else {
      return EditorState.createEmpty();
    }
  });

  useEffect(() => {
    let raw = convertToHTML(editorState.getCurrentContent());
    setValue('description', raw);
  }, [editorState, setValue]);

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(goNextSection)}
      className="h-pageContent  flex flex-col"
    >
      <div className="flex bg-gray-light flex-1 px-10 py-8 overflow-auto">
        <div className="w-1/3">
          <div className="w-70 mx-auto">
            <label className="mb-2">Cover Image</label>
            <Controller
              control={control}
              name="cover_image"
              render={({ field }) => (
                <ImageInputFile
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  className="h-60 w-60 text-sm"
                />
              )}
            />
            <p className="fieldError">{errors?.cover_image?.message}</p>
          </div>
        </div>
        <div className="w-2/3">
          <label className="mb-2">Workout Title</label>
          <div className="mb-6">
            <input className="w-full" type="text" {...register('name')} />
            <p className="fieldError">{errors?.name?.message}</p>
          </div>
          <div className="flex mb-6">
            <div className="w-1/2 pr-1">
              <label className="mb-2">Category</label>
              <Controller
                name="category"
                control={control}
                render={({ field }) => (
                  <SimpleSingleSelect
                    name="category"
                    options={categoryOptions}
                    onChange={field.onChange}
                    value={field.value}
                    className={clsx(errors.category && 'error')}
                  />
                )}
              />
              <p className="fieldError">{errors?.category?.message}</p>
            </div>
            <div className="w-1/2 pl-1">
              <label className="mb-2">Level</label>
              <Controller
                name="difficulty_level"
                control={control}
                render={({ field }) => (
                  <SimpleSingleSelect
                    name="difficulty_level"
                    options={athleteDifficultyLevelOptions}
                    onChange={field.onChange}
                    value={field.value}
                    className={clsx(errors.difficulty_level && 'error')}
                  />
                )}
              />
              <p className="fieldError">{errors?.difficulty_level?.message}</p>
            </div>
          </div>
          <div className="mb-6">
            <label className="mb-2">Workout Length</label>
            <div className="flex">
              <div className="w-1/2 pr-1">
                <RadioButton
                  className="h-10 w-full"
                  label="Quick (15 mins)"
                  value="quick"
                  {...register('type')}
                />
              </div>
              <div className="w-1/2 pl-1">
                <RadioButton
                  className="h-10 w-full"
                  label="Normal"
                  value="normal"
                  {...register('type')}
                />
              </div>
            </div>
            <p className="fieldError">{errors?.type?.message}</p>
          </div>
          <label className="mb-2">Description</label>
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={{ options: ['inline'] }}
          />
          <p className="fieldError">{errors?.description?.message}</p>
        </div>
      </div>
      <div className="flex py-4">
        <button className="w-32 outlined_primary narrow mr-4" onClick={goBack} type="button">
          <ChevronLeftIcon /> <p>Back</p>
        </button>
        <div className="flex-1" />
        <button
          className="w-32 narrow  text-teal-normal mr-4  p-2"
          type="button"
          onClick={onClickSaveAsDraft}
        >
          Save as draft
        </button>
        <button className="w-32 primary narrow">Next</button>
      </div>
      {isOptionsLoading ? <Throbber withMask /> : null}
    </form>
  );
}

export default WorkoutDetailsForm;
