import { WorkoutBuildFormData } from '../modules/workouts/types';

enum Duration {
  M15 = '15',
  M30 = '30',
  M45 = '45',
  M60 = '60',
}
enum Stage {
  Activation = 'activation',
  Warmup = 'warmup',
  Mainset = 'mainset',
}
type StageAggreation = {
  [key in Stage]: WorkoutBuildFormData['exercises'];
};
type DurationStageAggreation = {
  [key in Duration]: StageAggreation;
};

export function ExerciseToAggregation(
  exercises: WorkoutBuildFormData['exercises']
): DurationStageAggreation {
  const pattern: DurationStageAggreation = generateBlankPattern();
  for (const duration of Object.values(Duration)) {
    for (const stage of Object.values(Stage)) {
      for (const ex of exercises.filter(
        (x) => x.stage === stage && x.workout_duration === parseInt(duration)
      )) {
        pattern[duration][stage].push(ex);
      }
    }
  }

  return pattern;
}

export function AggregationToExercise(
  aggregation: DurationStageAggreation
): WorkoutBuildFormData['exercises'] {
  const exercises: WorkoutBuildFormData['exercises'] = [];
  for (const duration of Object.values(Duration)) {
    for (const stage of Object.values(Stage)) {
      for (const ex of aggregation[duration][stage]) {
        exercises.push(ex);
      }
    }
  }
  return exercises;
}

export function moveItemUpByLane(exercises: WorkoutBuildFormData['exercises'], unique_id: string) {
  const aggregation = getLaneAndIndex(exercises, unique_id, (lane, index) => {
    if (index > 0) {
      moveItem(lane, index, index - 1);
    }
  });

  return AggregationToExercise(aggregation);
}

export function moveItemDownByLane(
  exercises: WorkoutBuildFormData['exercises'],
  unique_id: string
) {
  const aggregation = getLaneAndIndex(exercises, unique_id, (lane, index) => {
    if (index !== -1 && index < lane.length - 1) {
      moveItem(lane, index, index + 1);
    }
  });

  return AggregationToExercise(aggregation);
}

function getLaneAndIndex(
  exercises: WorkoutBuildFormData['exercises'],
  unique_id: string,
  callback: (lane: WorkoutBuildFormData['exercises'], index: number) => void
) {
  const exe = exercises.find((e) => e.unique_id === unique_id);
  const aggregation = ExerciseToAggregation(exercises);

  const laneArray = aggregation[`${exe.workout_duration}`][exe.stage];
  const localIndex = laneArray.findIndex((e) => e.unique_id === unique_id);

  callback(laneArray, localIndex);

  aggregation[`${exe.workout_duration}`][exe.stage] = laneArray;

  return aggregation;
}

function moveItem<T>(array: Array<T>, from: number, to: number) {
  const f = array.splice(from, 1)[0];
  array.splice(to, 0, f);
}
function generateBlankPattern(): DurationStageAggreation {
  return {
    [Duration.M15]: {
      [Stage.Activation]: [],
      [Stage.Warmup]: [],
      [Stage.Mainset]: [],
    },
    [Duration.M30]: {
      [Stage.Activation]: [],
      [Stage.Warmup]: [],
      [Stage.Mainset]: [],
    },
    [Duration.M45]: {
      [Stage.Activation]: [],
      [Stage.Warmup]: [],
      [Stage.Mainset]: [],
    },
    [Duration.M60]: {
      [Stage.Activation]: [],
      [Stage.Warmup]: [],
      [Stage.Mainset]: [],
    },
  };
}
